<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="申请详情">
        <el-button
            slot="extra"
            class="xxd-button"
            size="mini"
            style="color: #fff"
            @click="goBack"
        >上一页
        </el-button
        >
        <el-descriptions :column="2" border class="margin-top">
          <el-descriptions-item>
            <template slot="label" style="width: 50px !important">
              单号
            </template>
            {{ model.code }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 申请金额</template>
            {{ model.amount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 申请提现人</template>
            {{ model.settlementName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 手机号码</template>
            {{ model.mobile }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 申请时余额</template>
            {{ model.balanceBefore }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 目前余额</template>
            {{ model.balanceAfter }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 申请时间</template>
            {{ model.createTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 状态</template>
            {{ model.status }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 审核时间</template>
            {{ model.updateTime }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
      <easy-card title="申请人信息">
        <el-descriptions :column="2" border class="margin-top">
          <el-descriptions-item>
            <template slot="label" style="width: 50px !important">
              开户行类型
            </template>
            {{ model.bankType }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 银行总行名称</template>
            {{ model.bankHeadOfficeName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 银行总行行号</template>
            {{ model.bankHeadOfficeNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 开户分行名称</template>
            {{ model.accountOpeningBranchName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 账号</template>
            {{ model.account }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 银行开户行</template>
            {{ model.bankAccountName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 手机号码</template>
            {{ model.mobile }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
      <easy-card title="申请记录">
        <el-table
            v-loading="isLoading"
            :data="historyList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              fixed
              label="单号"
              prop="code"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="申请提现人"
              prop="settlementName"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="手机号码"
              prop="mobile"
              width="130"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="申请金额"
              prop="amount"
              width="140"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="申请时余额"
              prop="balanceBefore"
              width="140"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="目前余额"
              prop="balanceAfter"
              width="140"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="申请时间"
              prop="createTime"
              width="240"
          >
            <template slot-scope="scope">
              <span>{{ dateFormat(parseInt(scope.row.createTime)) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="状态"
              prop="status"
              width="100"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">通过</span>
              <span v-if="scope.row.status == 2">驳回</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="审核时间"
              prop="updateTime"
              width="240"
          >
            <template slot-scope="scope">
              <span>{{ dateFormat(parseInt(scope.row.updateTime)) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </easy-card>
    </div>
  </div>
</template>

<script>
import {getHistoryRecords} from "@/api/finance";
import {dateFormat} from "@/utils/utils";

export default {
  name: "cashout_detail",
  props: {
    model: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoading: true,
      historyList: [],
    };
  },
  mounted() {
    this.getHistoryRecords();
  },
  watch: {
    "model.id"() {
      this.getHistoryRecords();
    },
  },
  methods: {
    dateFormat(date, fmt = "yyyy-mm-dd HH:MM:SS") {
      if (!date) {
        return date;
      }

      date = !isNaN(date) ? new Date(date) : date;
      return dateFormat(fmt, date);
    },
    goBack() {
      this.$emit("goback");
    },
    init(model) {
      this.model = model;
      this.getHistoryRecords();
    },
    async getHistoryRecords() {
      if (!this.model.settlementUserId) {
        return;
      }
      this.isLoading = true;
      const res = await getHistoryRecords(this.model.settlementUserId);
      this.isLoading = false;
      if (res && res.code === 30) {
        const returnObject = res.returnObject;
        this.historyList = returnObject;
      }
    },
  },
};
</script>
