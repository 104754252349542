<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="businessType">
          <el-select
              v-model="query.status"
              clearable
              placeholder="请选择-状态"
              style="width: 260px"
          >
            <el-option label="审核通过" value="1"></el-option>
            <el-option label="审核驳回" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="parkingTimeType">
          <el-date-picker
              v-model="query.searchDate"
              align="right"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
<!--          <icon-button icon="share" title="导出" @click.native="exportData"/>-->
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              fixed
              label="单号"
              prop="code"
              width="300"
          >
          </el-table-column>
          <el-table-column
              align="left"
              label="申请提现人"
              prop="settlementName"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="手机号码"
              prop="mobile"
              width="130"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="申请金额"
              prop="amount"
              width="140"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="申请时余额"
              prop="balanceBefore"
              width="140"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="目前余额"
              prop="balanceAfter"
              width="140"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="申请时间"
              prop="createTime"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="状态"
              prop="status"
              width="100"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">通过</span>
              <span v-if="scope.row.status == 2">驳回</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="审核时间"
              prop="updateTime"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              width="140"
          >
            <template slot-scope="scope">
              <el-button
                  v-if="scope.row.status == 0"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="examine(scope.row)"
              >审核
              </el-button
              >
              <el-button
                  class="common-text"
                  size="small"
                  type="text"
                  @click="showDetail(scope.row)"
              >详情
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <FullScreenDialog ref="cashoutDetailDialog">
      <CashoutDetail ref="cashoutDetail" :model="currentRow" @goback="goBack"/>
    </FullScreenDialog>
    <el-dialog
        :append-to-body="true"
        :visible.sync="dialogVisible"
        class="common-dialog"
        title="申请审核"
        width="1000px"
    >
      <easy-card title="申请人信息">
        <el-descriptions :column="2" border class="margin-top">
          <el-descriptions-item>
            <template slot="label" style="width: 50px !important">
              开户行类型
            </template>
            {{ currentRow.bankType }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 银行总行名称</template>
            {{ currentRow.bankHeadOfficeName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 银行总行行号</template>
            {{ currentRow.bankHeadOfficeNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 开户分行名称</template>
            {{ currentRow.accountOpeningBranchName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 账号</template>
            {{ currentRow.account }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 银行开户行</template>
            {{ currentRow.bankAccountName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 手机号码</template>
            {{ currentRow.mobile }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
      <easy-card title="申请金额">
        <el-descriptions :column="2" border class="margin-top">
          <el-descriptions-item>
            <template slot="label"> 申请金额</template>
            {{ currentRow.amount }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doExamine('2')">拒绝</el-button>
        <el-button type="primary" @click="doExamine('1')">审核通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import FullScreenDialog from "@/components/FullScreenDialog";
import CashoutDetail from "./detail.vue";
import {doCashOutExamine, getCashoutList} from "@/api/finance";

export default {
  name: "cash-out",
  mixins: [BaseMixin, Permission],
  components: {
    FullScreenDialog,
    CashoutDetail,
  },
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      query: {
        searchDate: [],
        status: "",
      },
      currentRow: {},
      dataList: [1],
      model: {},
    };
  },
  mounted() {
  },
  methods: {
    async examine(row) {
      this.dialogVisible = true;
      this.currentRow = row;
    },
    goBack() {
      this.$refs.cashoutDetailDialog.hide();
    },
    doSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      if (params.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      const res = await getCashoutList(params);
      this.isLoading = false;
      if (res && res.code === 30) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
      }
    },

    async doExamine(status) {
      const res = await doCashOutExamine({
        withdrawRecordId: this.currentRow.id,
        status: status,
      });

      if (res && res.code === 30) {
        this.dialogVisible = false;
        this.doSearch();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      }
    },
    resetQueryForm() {
    },
    showDetail(row) {
      this.currentRow = row;
      this.$refs.cashoutDetailDialog.show();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
