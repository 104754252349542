<template>
  <div v-if="visible" class="full-screen-dialog">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "full-screen-dialog",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.full-screen-dialog {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #F4F7FC;
  overflow: auto;
}
</style>
