import request, {exportService} from "@/utils/request";
// import { mockService } from "@/utils/request";
import {objectToQueryString} from "@/utils/utils";

// 查询收入流水
export function searchIncomeFlow(params) {
    return request.get(
        "/MoneyManagement/searchIncomeFlow?" + objectToQueryString(params)
    );
}

// 充值查询
export function searchRecharge(params) {
    return request.get(
        "/MoneyManagement/searchRecharge?" + objectToQueryString(params)
    );
}

// 交易查询
export function searchTrade(params) {
    return request.post(
        "/operation/tradeOrderList?" + objectToQueryString(params)
    );
}

// 获取支付日志
// export function searchPayLog(params) {
//   return request.get(
//     "/MoneyManagement/searchPayLog?" + objectToQueryString(params)
//   );
// }

//查询支付日志
export function searchpaylog(params) {
    return request.get("/pay/searchpaylog?" + objectToQueryString(params));
}

//查看支付日志详情
export function paylogdetail(id) {
    return request.post("/pay/paylogdetail?orderId=" + id);
}

/**
 * 导出收入流水
 * @param {*} params
 * @returns
 */
export function exportIncomeFlow(params) {
    return exportService.get('/MoneyManagement/searchIncomeFlow?' + objectToQueryString(params));
}

// 获取提现记录
export function getCashoutList(params) {
    return request.get('/wallet/getWithdrawRecord?' + objectToQueryString(params));
    // console.log(params);
    // return {
    //   result: true,
    //   code: 30,
    //   message: 'ok',
    //   returnObject: {
    //     pageNo: 1,
    //     pageSize: 10,
    //     count: 100,
    //     list: [{
    //       id: '123',
    //       settlementUserId: '',
    //       settlementName: '青岛景立',
    //       mobile: '13333333333',
    //       amount: 10,
    //       balanceBefore: 10,
    //       balanceAfter: 10,
    //       createTime: '2022-11-01 14:14:14',
    //       updateTime: '2022-11-01 14:14:14',
    //       status: '',
    //       code: 'C202211012012',
    //       bankType: '农业银行',
    //       bankHeadOfficeName: '中国农业银行股份有限公司',
    //       bankHeadOfficeNumber: '103100000018',
    //       accountOpeningBranchName: '青岛蓝谷支行',
    //       account: '38030101040035081',
    //       bankAccountName: '青岛景立智能停车管理有限公司'
    //     }],
    //     totalPage: 10
    //   }
    // };
}

// 提现审批
export function doCashOutExamine(params) {
    return request.get('/wallet/withdrawVerify?' + objectToQueryString(params));
}

// 根据id获取提现详情
export function getWithdrawRecordById(withdrawRecordId) {
    return request.get('/wallet/getWithdrawRecordById?withdrawRecordId=' + withdrawRecordId);
}

// 获取历史申请记录
export function getHistoryRecords(settlementUserId) {
    return request.get('/wallet/applicationRecord?settlementUserId=' + settlementUserId);
}
